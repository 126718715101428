import List from "./List";
import New from "./New";
import Detail from "./Detail";
import General from "./General";

export default {
  List,
  New,
  Detail,
  General
};
