import Summary from "./Summary";
import Placeholder from "./Placeholder";
import Detail from "./Detail";
import Header from "./Header";
import Filters from "./Filters";

export default {
  Summary,
  Placeholder,
  Detail,
  Header,
  Filters
};
