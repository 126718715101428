import List from "./List";
import New from "./New";
import General from "./General";
import Body from "./Body";
import Detail from "./Detail";

export default {
  List,
  New,
  General,
  Body,
  Detail
};
