import Pagination from "./Pagination";
import EdgeLockScroll from "./EdgeLockScroll";
import EntityCount from "./EntityCount";
import IconComposer from "./IconComposer";
import LockBodyScroll from "./LockBodyScroll";
import ScrollToTop from "./ScrollToTop";
import SetCSSProperty from "./SetCSSProperty";
import SkipLink from "./SkipLink";
import LabelWithIcon from "./LabelWithIcon";

export default {
  Pagination,
  EdgeLockScroll,
  EntityCount,
  IconComposer,
  LockBodyScroll,
  ScrollToTop,
  SkipLink,
  LabelWithIcon,
  SetCSSProperty
};
