import Annotate from "./Annotate";
import Link from "./Link";
import ReadingGroups from "./ReadingGroups";
import Share from "./Share";

export default {
  Annotate,
  Link,
  ReadingGroups,
  Share
};
