import React, { Component } from "react";
import PropTypes from "prop-types";

export default class FormCoverUploadPlaceholder extends Component {
  static displayName = "Form.CoverUploadPlaceholder";

  static propTypes = {
    color: PropTypes.string
  };

  render() {
    return (
      <svg
        version="1.1"
        className="cover-upload-placeholder"
        x="0px"
        y="0px"
        width="126px"
        height="126px"
        viewBox="0 0 126.03632 126.25824"
      >
        {/* Disable max-length on linter for long SVG path declarations */}
        {/* eslint-disable max-len */}
        <g>
          <g>
            <path
              className="background"
              d="M125.53632,126.25824H8.06415c-0.27637,0-0.5-0.22363-0.5-0.5s0.22363-0.5,0.5-0.5h116.97217V8.28656c0-0.27637,0.22363-0.5,0.5-0.5s0.5,0.22363,0.5,0.5v117.47168C126.03632,126.03461,125.81268,126.25824,125.53632,126.25824z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="background"
              d="M121.50409,122.11469H4.03192c-0.27637,0-0.5-0.22363-0.5-0.5s0.22363-0.5,0.5-0.5h116.97217V4.14349c0-0.27637,0.22363-0.5,0.5-0.5s0.5,0.22363,0.5,0.5v117.47119C122.00409,121.89105,121.78046,122.11469,121.50409,122.11469z"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  className="background"
                  d="M116.4718,1v115.4718H1V1H116.4718 M117.4718,0H0v117.4718h117.4718V0L117.4718,0z"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <path
            className="background"
            d="M67.42499,60.83441l-7.56659-7.5661c-0.09216-0.09229-0.20282-0.16534-0.3252-0.21606c-0.24451-0.10114-0.51978-0.10114-0.76428,0c-0.12238,0.05072-0.23303,0.12378-0.3252,0.21606l-7.5661,7.5661c-0.39062,0.39062-0.39062,1.02344,0,1.41406s1.02344,0.39062,1.41406,0l5.85938-5.85938v12.71875c0,0.55273,0.44775,1,1,1s1-0.44727,1-1V56.38904l5.85986,5.85944c0.19531,0.19531,0.45117,0.29297,0.70703,0.29297s0.51172-0.09766,0.70703-0.29297C67.81561,61.85785,67.81561,61.22504,67.42499,60.83441z"
          />
        </g>
        <g>
          <path
            className="background"
            d="M6.01068,6.12183v105.00281h105.56836V6.12183H6.01068z M90.21796,72.24164c-2.44336,3.10938-6.09082,5.12891-10.00684,5.54199l-0.10352,0.00586l-44.97559,0.04004c-3.32471-0.2168-6.52832-2.3125-8.85547-5.75098c-2.7251-4.02637-2.89355-9.18896-0.43945-13.47412c2.30811-4.03027,6.37549-6.25879,10.88086-5.97266c2.10547,0.13623,4.15332,0.81396,5.93555,1.94482c0.38672-8.59277,7.49854-15.46387,16.18506-15.46387c6.66064,0,12.57275,4.04053,15.02588,10.15771c2.20117-0.75879,4.57324-0.98438,6.92285-0.6499c6.34863,0.90088,11.57617,6.16504,12.42969,12.51709C93.75604,65.15179,92.69061,69.09613,90.21796,72.24164z"
          />
        </g>
        {/* eslint-enable max-len */}
      </svg>
    );
  }
}
